.varsLight() {
    --weui-BG-0: #ededed;
    --weui-BG-1: #f7f7f7;
    --weui-BG-2: #fff;
    --weui-BG-3: #f7f7f7;
    --weui-BG-4: #4c4c4c;
    --weui-BG-5: #fff;
    --weui-FG-0: rgba(0, 0, 0, 0.9);
    --weui-FG-HALF: rgba(0, 0, 0, 0.9);
    --weui-FG-1: rgba(0, 0, 0, 0.5);
    --weui-FG-2: rgba(0, 0, 0, 0.3);
    --weui-FG-3: rgba(0, 0, 0, 0.1);
    --weui-RED: #fa5151;
    --weui-ORANGE: #fa9d3b;
    --weui-YELLOW: #ffc300;
    --weui-GREEN: #91d300;
    --weui-LIGHTGREEN: #95ec69;
    --weui-BRAND: #07c160;
    --weui-BLUE: #10aeff;
    --weui-INDIGO: #1485ee;
    --weui-PURPLE: #6467f0;
    --weui-WHITE: #fff;
    --weui-LINK: #576b95;
    --weui-TEXTGREEN: #06ae56;
    --weui-FG: black;
    --weui-BG: white;
    --weui-TAG-TEXT-ORANGE: #fa9d3b;
    --weui-TAG-BACKGROUND-ORANGE: rgba(250, 157, 59, 0.1);
    --weui-TAG-TEXT-GREEN: #06ae56;
    --weui-TAG-BACKGROUND-GREEN: rgba(6, 174, 86, 0.1);
    --weui-TAG-TEXT-BLUE: #10aeff;
    --weui-TAG-BACKGROUND-BLUE: rgba(16, 174, 255, 0.1);
    --weui-TAG-TEXT-BLACK: rgba(0, 0, 0, 0.5);
    --weui-TAG-BACKGROUND-BLACK: rgba(0, 0, 0, 0.05);
}
