@import 'vars/light';
@import 'vars/dark';

.dark(@rule) {
    body[data-weui-theme='dark'] & {
        @rule();
    }

    @media (prefers-color-scheme: dark) {
        body:not([data-weui-theme='light']) & {
            @rule();
        }
    }
}

.setColor(@var, @color) {
    .setColor(@var, @color, @color);
}

.setColor(@var, @light, @dark) {
    ._setColor({
            @{var}: @light;
    }, {
            @{var}: @dark;
    });
}
._setColor(@lightRule, @darkRule) {
    body {
        @lightRule();
    }
    body[data-weui-theme='dark'] {
        @darkRule();
    }

    @media (prefers-color-scheme: dark) {
        body:not([data-weui-theme='light']) {
            @darkRule();
        }
    }
}