.varsDark() {
    --weui-BG-0: #111111;
    --weui-BG-1: #1e1e1e;
    --weui-BG-2: #191919;
    --weui-BG-3: #202020;
    --weui-BG-4: #404040;
    --weui-BG-5: #2c2c2c;
    --weui-FG-0: rgba(255, 255, 255, 0.8);
    --weui-FG-HALF: rgba(255, 255, 255, 0.6);
    --weui-FG-1: rgba(255, 255, 255, 0.5);
    --weui-FG-2: rgba(255, 255, 255, 0.3);
    --weui-FG-3: rgba(255, 255, 255, 0.05);
    --weui-RED: #fa5151;
    --weui-ORANGE: #c87d2f;
    --weui-YELLOW: #cc9c00;
    --weui-GREEN: #74a800;
    --weui-LIGHTGREEN: #3eb575;
    --weui-BRAND: #07c160;
    --weui-BLUE: #10aeff;
    --weui-INDIGO: #1196ff;
    --weui-PURPLE: #8183ff;
    --weui-WHITE: rgba(255, 255, 255, 0.8);
    --weui-LINK: #7d90a9;
    --weui-TEXTGREEN: #259c5c;
    --weui-FG: white;
    --weui-BG: black;
    --weui-TAG-TEXT-ORANGE: rgba(250, 157, 59, 0.6);
    --weui-TAG-BACKGROUND-ORANGE: rgba(250, 157, 59, 0.1);
    --weui-TAG-TEXT-GREEN: rgba(6, 174, 86, 0.6);
    --weui-TAG-BACKGROUND-GREEN: rgba(6, 174, 86, 0.1);
    --weui-TAG-TEXT-BLUE: rgba(16, 174, 255, 0.6);
    --weui-TAG-BACKGROUND-BLUE: rgba(16, 174, 255, 0.1);
    --weui-TAG-TEXT-BLACK: rgba(255, 255, 255, 0.5);
    --weui-TAG-BACKGROUND-BLACK: rgba(255, 255, 255, 0.05);
};
